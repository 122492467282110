import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

function Checkbox({ name, value, label, onChange, onBlur, error, className = 'uk-margin' }) {
    const innerOnChange = useCallback(({ target: { name, checked: value } }) => onChange({ name, value }), [onChange]);

    return (
        <div className={className}>
            <div className="uk-form-controls">
                <label>
                    <input
                        className="uk-checkbox uk-form-controls uk-margin-small-right"
                        type="checkbox"
                        name={name}
                        checked={value}
                        onChange={innerOnChange}
                        onBlur={onBlur}
                    />
                    {label}
                </label>
                {error && <div className="uk-text-danger">{error}</div>}
            </div>
        </div>
    );
}

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    label: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    error: PropTypes.string,
    className: PropTypes.string,
};

export default Checkbox;

import { useMemo } from 'react';
import useSWR from 'swr';
import { apiClient } from '../../helpers/apiHelperV2';

const fetcher = (url, params) => apiClient.get(url, { params }).then(res => res.data);

function useServiceStations(enabled, { zipCode, carMake, range, categoryUrl, gesellschafter, tecarHaendler, wheels, productVariantId }) {
    const params = useMemo(() => {
        return { zip: zipCode, marke: carMake, range, categoryUrl, gesellschafter, tecarHaendler, wheels, productVariantId };
    }, [carMake, categoryUrl, gesellschafter, productVariantId, range, tecarHaendler, wheels, zipCode]);

    const { data, error, isLoading } = useSWR(enabled ? ['/api/v1/LocationsForShopFinder', params] : null, fetcher);

    return {
        wheelInfoText: data?.wheelInfoText,
        serviceStations: data?.data?.standorte,
        isLoading,
        isError: error,
    };
}

export default useServiceStations;

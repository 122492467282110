import React, { useState } from 'react';
import TireForm from './TireForm';
import Modal from '../Modal/Modal';

function TireModal({ children, gesellschafter }) {
    const [open, setOpen] = useState(false);

    function openFormModal() {
        setOpen(true);
    }

    function closeFormModal() {
        setOpen(false);
    }

    return (
        <>
            {React.Children.map(children, child =>
                React.cloneElement(child, {
                    ...child.props,
                    onClick: openFormModal,
                })
            )}
            <Modal isOpen={open} onRequestClose={() => closeFormModal()} contentLabel="Reifenanfrage Modal" fullWidth>
                <TireForm closeFormModal={closeFormModal} gesellschafter={gesellschafter} />
            </Modal>
        </>
    );
}

export default TireModal;

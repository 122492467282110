import React from 'react';
import { carTypes } from '../../../helpers/tiresHelper';
import { useSelector } from 'react-redux';
import { validSeasonOptionsSelector } from '../../../modules/selectors/standort/tires';
import Select from '../form-elements/Select';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import Button from '../form-elements/Button';

const allLabel = 'Alle';

function TireConfiguratorBody({
    filterValues,
    options,
    season,
    isButtonEnabled,
    onSubmit,
    onSeasonChange,
    onFilterValuesChange,
    submitButtonLabel,
    setZipCode,
    showZipInput,
}) {
    const validSeasons = useSelector(validSeasonOptionsSelector);
    const markenStandort = useSelector(markenStandortSelector);

    return (
        <div className=" uk-background-primary uk-padding uk-light">
            <h3>FINDEN SIE DEN PASSENDEN REIFEN</h3>
            {showZipInput ? (
                <p className="uk-text-light">
                    Geben Sie die Reifendaten sowie Ihre Postleitzahl ein und finden ein Autohaus direkt in Ihrer Nähe. Dort können Sie Ihre
                    Reifen reservieren und vor Ort abholen. Auf Wunsch wird Ihnen auch gern ein Montageangebot erstellt.
                </p>
            ) : (
                <ul className={!markenStandort.isTechno ? 'uk-list uk-list-decimal' : 'uk-list'}>
                    <li>Reifendaten eingeben und passenden Reifen auswählen</li>
                    {!markenStandort.isTechno && (
                        <>
                            <li>Bestellen Sie zur Abholung im Autohaus</li>
                            <li>Auf Wunsch Montage vor Ort buchbar</li>
                            {markenStandort.customNumberInterpneu && (
                                <li>Reifen der Marke TECAR können auch an eine Wunschadresse geliefert werden.</li>
                            )}
                        </>
                    )}
                </ul>
            )}
            <form
                className="uk-form uk-margin-large"
                onSubmit={e => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <div className="uk-flex uk-flex-middle uk-grid" data-uk-grid="" data-uk-margin="">
                    <div className="uk-width-1-4@s uk-width-1-1@m uk-width-1-4@l uk-margin-small-top">
                        <label className="uk-text-bold">Fahrzeug*</label>
                    </div>
                    <div className="uk-width-3-4@s uk-width-1-1@m uk-width-3-4@l uk-margin-small-top">
                        <Select name="fahrzeug" value={filterValues.fahrzeug} options={carTypes} onChange={onFilterValuesChange} />
                    </div>
                    <div className="uk-width-1-4@s uk-width-1-1@m uk-width-1-4@l uk-margin-small-top">
                        <label className="uk-text-bold">Saison*</label>
                    </div>
                    <div className="uk-width-3-4@s uk-width-1-1@m uk-width-3-4@l uk-margin-small-top">
                        <Select name="saison" value={season} options={validSeasons} onChange={onSeasonChange} />
                    </div>
                    <div className="uk-width-1-4@s uk-width-1-1@m uk-width-1-4@l uk-margin-small-top">
                        <label className="uk-text-bold">Breite/Höhe/Zoll*</label>
                    </div>
                    <div className="uk-width-3-4@s uk-width-1-1@m uk-width-3-4@l uk-margin-small-top">
                        <div className="uk-child-width-1-3 uk-grid-small uk-grid" data-uk-grid="">
                            <div>
                                <Select
                                    name="breite"
                                    value={filterValues.breite}
                                    options={options.breite.map(option => ({
                                        label: option || allLabel,
                                        value: option,
                                    }))}
                                    onChange={onFilterValuesChange}
                                />
                            </div>
                            <div>
                                <Select
                                    name="hoehe"
                                    value={filterValues.hoehe}
                                    options={options.hoehe.map(option => ({
                                        label: option || allLabel,
                                        value: option,
                                    }))}
                                    onChange={onFilterValuesChange}
                                />
                            </div>
                            <div>
                                <Select
                                    name="durchmesser"
                                    value={filterValues.durchmesser}
                                    options={options.durchmesser.map(option => ({
                                        label: option || allLabel,
                                        value: option,
                                    }))}
                                    onChange={onFilterValuesChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4@s uk-width-1-1@m uk-width-1-4@l uk-margin-small-top">
                        <label className="uk-text-bold">Tragfähigkeit/ Geschwindigkeit</label>
                    </div>
                    <div className="uk-width-3-4@s uk-width-1-1@m uk-width-3-4@l uk-margin-small-top">
                        <div className="uk-child-width-1-2 uk-grid-small uk-grid" data-uk-grid="">
                            <div>
                                <Select
                                    name="tragfaehigkeit"
                                    value={filterValues.tragfaehigkeit}
                                    options={options.tragfaehigkeit.map(option => ({
                                        label: option || allLabel,
                                        value: option,
                                    }))}
                                    onChange={onFilterValuesChange}
                                />
                            </div>
                            <div>
                                <Select
                                    name="geschwindigkeitsindex"
                                    value={filterValues.geschwindigkeitsindex}
                                    options={options.geschwindigkeitsindex.map(option => ({
                                        label: option || allLabel,
                                        value: option,
                                    }))}
                                    onChange={onFilterValuesChange}
                                />
                            </div>
                        </div>
                    </div>
                    {showZipInput && (
                        <>
                            <div className="uk-width-1-4@s uk-width-1-1@m uk-width-1-4@l uk-margin-small-top">
                                <label className="uk-text-bold">Postleitzahl*</label>
                            </div>
                            <div className="uk-width-3-4@s uk-width-1-1@m uk-width-3-4@l uk-margin-small-top">
                                <div className="uk-form-controls">
                                    <input className="uk-input" name="plz" onChange={({ target: { value } }) => setZipCode(value)} />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="uk-width-1-4@s uk-width-1-1@m uk-width-1-4@l uk-margin-small-top uk-visible@s" />
                    <div className="uk-width-3-4@s uk-width-1-1@m uk-width-3-4@l uk-margin-small-top">
                        <div className="uk-grid-small uk-grid" data-uk-grid="">
                            <div className="uk-width-1-1">
                                <Button fullWidth type="submit" disabled={!isButtonEnabled} modifier="secondary">
                                    {submitButtonLabel}
                                </Button>
                            </div>
                            <div className="uk-width-1-1">
                                <p className="uk-text-small">Pflichtfelder sind mit * gekennzeichnet.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default TireConfiguratorBody;

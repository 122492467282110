import React from 'react';
import { useStandortHistory } from './StandortHistory';
import { useLocation, useParams } from 'react-router-dom';

function withStandortRouter(WrappedComponent) {
    return function WithStandortRouter(props) {
        const history = useStandortHistory();
        const location = useLocation();
        const params = useParams();

        return <WrappedComponent {...props} history={history} location={location} match={{ params }} />;
    };
}

export default withStandortRouter;

import React from 'react';
import classnames from 'classnames';
import TireModal from '../Anfrage/TireModal';
import ContactModal from '../Anfrage/ContactModal';
import Button from '../form-elements/Button';
import { InternalLink } from '../form-elements/Link';

function ServiceStationCard({ positionNumber, tecarBackground, headline, callToAction, footerChildren }) {
    return (
        <div>
            <div className="uk-card uk-card-small">
                <div
                    className={classnames(
                        'uk-card-body uk-flex uk-flex-column',
                        tecarBackground ? 'aps-tecar-background uk-light' : 'uk-card-primary'
                    )}
                >
                    <div className="uk-grid uk-grid-small">
                        <div className="uk-width-auto">
                            <span className="uk-badge uk-background-muted">{positionNumber}</span>
                        </div>
                        <div className="uk-width-expand">
                            <h4>{headline}</h4>
                            <div>{callToAction}</div>
                        </div>
                    </div>
                </div>
                <div className="uk-card-footer uk-padding-remove">{footerChildren}</div>
            </div>
        </div>
    );
}

export function TecarConfiguratorCard({ serviceStationsLength = 0 }) {
    return (
        <ServiceStationCard
            positionNumber={serviceStationsLength !== 0 && serviceStationsLength + 1}
            tecarBackground
            headline="TECAR-Reifen online kaufen"
            callToAction="Passende Reifen aussuchen, online bezahlen und direkt zu Ihrer Wunschadresse liefern lassen."
            footerChildren={
                <InternalLink to="/home/tecarLandingpage/reifen-finden" newStandortUrl="de" modifier="secondary" fullWidth>
                    Zum Reifenkonfigurator
                </InternalLink>
            }
        />
    );
}

export function TireInquiryCard({ serviceStationsLength = 0 }) {
    return (
        <ServiceStationCard
            positionNumber={serviceStationsLength !== 0 && serviceStationsLength + 2}
            headline="Kein passendes Autohaus in der Nähe?"
            callToAction="Senden Sie uns hier eine Anfrage, unsere Partner Werkstatt wird Sie kontaktieren."
            footerChildren={
                <TireModal>
                    <Button fullWidth modifier="secondary" onClick={() => {}}>
                        Zum Anfrageformular
                    </Button>
                </TireModal>
            }
        />
    );
}

export function ContactInquiryCard({ serviceStationsLength = 0 }) {
    return (
        <ServiceStationCard
            positionNumber={serviceStationsLength !== 0 && serviceStationsLength + 1}
            headline="Kein passendes Autohaus in der Nähe?"
            callToAction="Senden Sie uns hier eine Anfrage, unsere Partner Werkstatt wird Sie kontaktieren."
            footerChildren={
                <ContactModal>
                    {({ openModal }) => (
                        <Button fullWidth modifier="secondary" onClick={openModal}>
                            Zum Anfrageformular
                        </Button>
                    )}
                </ContactModal>
            }
        />
    );
}

import {
    FETCH_GLOBAL_TIRE_OPTIONS_FAILURE,
    FETCH_GLOBAL_TIRE_OPTIONS_REQUEST,
    FETCH_GLOBAL_TIRE_OPTIONS_SUCCESS,
    FETCH_TIRE_OPTIONS_FAILURE,
    FETCH_TIRE_OPTIONS_REQUEST,
    FETCH_TIRE_OPTIONS_SUCCESS,
    FETCH_TIRES_FAILURE,
    FETCH_TIRES_REQUEST,
    FETCH_TIRES_SUCCESS,
    UPDATE_TIRES_FILTER_VALUES,
} from '../../actionTypes';
import { standortUrlSelector } from '../../selectors/url';
import {
    globalTireOptionsResponseSelector,
    tireOptionsForSelectedOptionsResponseSelector,
    tireOptionsResponseSelector,
    tiresOptionsSearchParamsForSelectedOptionsSelector,
    tiresOptionsSearchParamsSelector,
    tiresResponseSelector,
    tiresSearchParamsSelector,
} from '../../selectors/standort/tires';
import { shouldFetch } from '../../fetchStatus';
import { apiClient } from '../../../helpers/apiHelperV2';

export const fetchTiresIfNeeded = ({ selectedOptions }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);
        const searchParams = tiresSearchParamsSelector(state, { selectedOptions });
        const tiresResponse = tiresResponseSelector(state, { selectedOptions });

        if (shouldFetch(tiresResponse.status)) {
            dispatch({
                type: FETCH_TIRES_REQUEST,
                payload: { standortUrl, searchParams },
            });

            try {
                const res = await apiClient.get(`/api/v1/standorte/${standortUrl}/tires?${searchParams}`);

                dispatch({
                    type: FETCH_TIRES_SUCCESS,
                    payload: { standortUrl, searchParams, data: res.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_TIRES_FAILURE,
                    payload: { standortUrl, searchParams, error },
                });
            }
        }
    };
};

export const fetchTireOptionsIfNeeded = ({ season, filterByDimensions, selectedOptions }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);

        let searchParams;
        let tireOptionsResponse;
        if (selectedOptions) {
            searchParams = tiresOptionsSearchParamsForSelectedOptionsSelector(state, { selectedOptions, filterByDimensions });
            tireOptionsResponse = tireOptionsForSelectedOptionsResponseSelector(state, { selectedOptions, filterByDimensions });
        } else {
            searchParams = tiresOptionsSearchParamsSelector(state, { season, filterByDimensions });
            tireOptionsResponse = tireOptionsResponseSelector(state, { season, filterByDimensions });
        }

        if (shouldFetch(tireOptionsResponse.status)) {
            dispatch({
                type: FETCH_TIRE_OPTIONS_REQUEST,
                payload: { standortUrl, searchParams },
            });

            try {
                const res = await apiClient.get(`/api/v1/standorte/${standortUrl}/tires/options${searchParams ? `?${searchParams}` : ''}`);

                dispatch({
                    type: FETCH_TIRE_OPTIONS_SUCCESS,
                    payload: { standortUrl, searchParams, data: res.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_TIRE_OPTIONS_FAILURE,
                    payload: { standortUrl, searchParams, error },
                });
            }
        }
    };
};

export const fetchGlobalTireOptionsIfNeeded = ({ season, filterByDimensions }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const searchParams = tiresOptionsSearchParamsSelector(state, { season, filterByDimensions });
        const globalTireOptionsResponse = globalTireOptionsResponseSelector(state, { season, filterByDimensions });

        if (shouldFetch(globalTireOptionsResponse.status)) {
            dispatch({
                type: FETCH_GLOBAL_TIRE_OPTIONS_REQUEST,
                payload: { searchParams },
            });

            try {
                const res = await apiClient.get(`/api/v1/tires/options${searchParams ? `?${searchParams}` : ''}`);

                dispatch({
                    type: FETCH_GLOBAL_TIRE_OPTIONS_SUCCESS,
                    payload: { searchParams, data: res.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_GLOBAL_TIRE_OPTIONS_FAILURE,
                    payload: { searchParams, error },
                });
            }
        }
    };
};

export const updateTiresFilterValues = ({ standortUrl, filterValuesUpdate }) => ({
    type: UPDATE_TIRES_FILTER_VALUES,
    payload: { standortUrl, filterValuesUpdate },
});

import React from 'react';
import Input from '../form-elements/Input';
import * as Yup from 'yup';
import { apiClient } from '../../../helpers/apiHelperV2';
import SalutationInformation, { mergeSalutationInformationInitialValues, SalutationInformationSchemaFields } from './SalutationInformation';

function PersonalInformation({ errors, touched, values, onChange, onBlur }) {
    return (
        <>
            <SalutationInformation errors={errors} touched={touched} values={values} onChange={onChange} onBlur={onBlur} />
            <Input
                name="street"
                value={values.street}
                label="Straße und Hausnummer *"
                placeholder="Straße und Hausnummer"
                onChange={onChange}
                onBlur={onBlur}
                error={touched.street && errors.street ? errors.street : undefined}
            />
            <Input
                name="additionalAddress"
                value={values.additionalAddress}
                label="Adresszusatz"
                placeholder="Adresszusatz"
                onChange={onChange}
                onBlur={onBlur}
            />
            <div className="uk-margin uk-grid-small uk-grid" data-uk-grid="">
                <div className="uk-width-1-3@s">
                    <Input
                        name="zipCode"
                        value={values.zipCode}
                        label="Postleitzahl *"
                        placeholder="Postleitzahl"
                        onChange={onChange}
                        onBlur={onBlur}
                        error={touched.zipCode && errors.zipCode ? errors.zipCode : undefined}
                    />
                </div>
                <div className="uk-width-expand">
                    <Input
                        name="city"
                        value={values.city}
                        label="Ort *"
                        placeholder="Ort"
                        onChange={onChange}
                        onBlur={onBlur}
                        error={touched.city && errors.city ? errors.city : undefined}
                    />
                </div>
            </div>
            <Input
                name="country"
                value={values.country}
                label="Land *"
                placeholder="Land"
                onChange={onChange}
                onBlur={onBlur}
                error={touched.country && errors.country ? errors.country : undefined}
                disabled
            />
        </>
    );
}

export default PersonalInformation;

export const PersonalInformationSchemaFields = {
    ...SalutationInformationSchemaFields,
    street: Yup.string().required('Pflichtfeld'),
    additionalAddress: Yup.string(),
    zipCode: Yup.string()
        .required('Pflichtfeld')
        .test('zipCode', 'Bitte geben Sie eine gültige deutsche PLZ ein', async function (zipCode) {
            if (zipCode && zipCode.length === 5) {
                try {
                    const {
                        data: { isValid },
                    } = await apiClient.post('/api/v1/validation/zipCode', { zipCode });
                    return isValid;
                } catch (err) {
                    console.error(err);
                    return false;
                }
            }
        }),
    city: Yup.string().required('Pflichtfeld'),
    country: Yup.string().equals(['Deutschland']),
};

export const mergePersonalInformationInitialValues = values => ({
    ...mergeSalutationInformationInitialValues(values),
    street: values?.street ?? '',
    additionalAddress: values?.additionalAddress ?? '',
    zipCode: values?.zipCode ?? '',
    city: values?.city ?? '',
    country: 'Deutschland',
});

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import Image from '../../components/Image/Image';
import loadable from '@loadable/component';
import TireConfigurator from '../../components/TireConfigurator';
import { InternalLink } from '../../components/form-elements/Link';
import { TecarConfiguratorCard } from '../../components/ServiceStationFinder/ServiceStationCards';
import { hideTecarTile, showTecarTile } from '../../../modules/actions/floatingSidebar';
import { validDefaultSeasonSelector } from '../../../modules/selectors/standort/tires';

const ServiceStationFinder = loadable(() => import('../../components/ServiceStationFinder/ServiceStationFinder'));

function TecarFinder() {
    const { showConfigurator, zip, carMake } = useParams();
    const dispatch = useDispatch();
    const markenStandort = useSelector(markenStandortSelector);
    const validDefaultSeason = useSelector(validDefaultSeasonSelector);
    const [season, setSeason] = useState(validDefaultSeason);

    useEffect(() => {
        if (markenStandort.isTechno && !showConfigurator) {
            dispatch(showTecarTile());
            return () => {
                dispatch(hideTecarTile());
            };
        }
    }, [dispatch, markenStandort.isTechno, showConfigurator]);

    return (
        <>
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <h2 className="uk-text-light">IN WENIGEN KLICKS ZU IHREM TECAR REIFEN</h2>
                    <h1 className="uk-text-light">TECAR Reifen - unschlagbar in Sachen Preis und Leistung</h1>
                </div>
            </section>
            {markenStandort && markenStandort.isTechno && !showConfigurator ? (
                <section className="uk-section uk-section-default uk-section-small">
                    <div className="uk-container">
                        <ServiceStationFinder
                            prefilledZip={zip}
                            prefilledCarMake={carMake}
                            tecarHaendler
                            tecar
                            redirectUrl="/home/tecarLandingpage/reifen-finden"
                            buttonText="Zur Reifenauswahl"
                            trailingCards={({ serviceStationsLength }) => (
                                <TecarConfiguratorCard serviceStationsLength={serviceStationsLength} />
                            )}
                        />
                    </div>
                </section>
            ) : (
                <TireConfigurator season={season} onSeasonChange={({ value }) => setSeason(value)} isTecar />
            )}

            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <div className="uk-child-width-1-2@m uk-grid" data-uk-grid="">
                        <div>
                            <h4>TECAR Winterreifen – direkt in Ihrer Meister-Werkstatt kaufen</h4>
                            <p>
                                <b>
                                    Reifen von TECAR bieten Ihnen erstklassige Sicherheit und höchste Qualität zu fairen Preisen – und dies
                                    exklusiv aus Ihrem Autohaus. Und für (fast) jedes Auto. Allein die ESA+TECAR
                                </b>{' '}
                                SUPERGRIP PRO{' '}
                                <b>
                                    Winterreifen sind in 46 Dimensionen erhältlich. Darüber hinaus bringen sie eine erweiterte
                                    Reifengarantie von 3 Jahren mit.
                                </b>
                            </p>
                            <ul className="uk-list uk-list-bullet">
                                <li>Mit integrierter Profiltiefenanzeige (Winter-TWI) zur schnellen Verschleißerkennung</li>
                                <li style={{ "marginTop": "1px" }}>Direktes Einlenkverhalten beim Abbiegen auf verschneiter Straße</li>
                                <li style={{ "marginTop": "1px" }}>Hohe Sicherheit durch die optimierte Aufnahme und Verdrängung von Wasser und Matsch</li>
                                <li style={{ "marginTop": "1px" }}>Verbesserte Traktions- und Bremseigenschaften auf verschneiten Straßen</li>
                            </ul>
                        </div>

                        <div>
                            <Image src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/aps-landingpage-01-tecar-winterreifen-2023.jpg" alt="Tecar Reifen" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <div className="uk-child-width-1-2@m uk-grid uk-flex-middle" data-uk-grid="">
                        <div>
                            <Image
                                src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/aps-landingpage-02-tecar-winterreifen-2023.jpg"
                                alt="Tecar guenstige Sommerreifen"
                            />
                        </div>
                        <div>
                            <h4>Hochleistung selbst auf Eis und Schnee</h4>
                            <p>
                                Der <b>ESA+TECAR</b> SUPERGRIP PRO{' '}
                                <b>Winterreifen</b> überzeugt: mit starkem Preis und starker
                                Leistung! Bei den Winterreifentests wurde er vom <b>ADAC mit »befriedigend«</b> ausgezeichnet.{' '}
                                <b>ÖAMTC und TCS</b> sprachen jeweils ein <b>»empfehlenswert«</b> aus.
                            </p>
                            <p>
                                Der moderne Hochleistungs-Winterreifen ist genau richtig für{' '}
                                <b>alle, die beim Reifen sparen, aber in Sachen Sicherheit und Komfort keine Abstriche machen möchten.</b>{' '}
                                Selbst bei Matsch und anderem winterlichen Untergrund bleibt das Fahrverhalten erstklassig – dank
                                unterschiedlich abgewinkelter Einkerbungen in umlaufender Profilrichtung, zusätzlicher Griffspitzen, hoher
                                Lamellenstruktur und lateralen Griffkanten.
                            </p>
                            <p>
                                Übrigens: Einen Winterreifen erkennen Sie immer an der entsprechenden 
                                <b> Kennzeichnung für Winterreifen</b> durch das dreigezackte <b>Bergpiktogramm mit Schneeflocke in der Mitte </b>
                                (siehe Abbildung). Die Kennzeichnung finden Sie im Schulterbereich des Reifens.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <div className="uk-child-width-1-2@m uk-grid" data-uk-grid="">
                    <div>
                            <h4>Integrierte Profiltiefenanzeige zur schnellen Verschleißerkennung</h4>
                            <p className="uk-text-bold">
                                Reifenfachleute wissen: Die Schnee- und Nassgriff-Performance bei Winterreifen reduziert sich
                                überproportional schnell, sobald sich die Restprofiltiefe auf 4 mm oder weniger reduziert.
                            </p>
                            <p>
                                Sobald sich der <b>TWI</b> (Tread Wear Indicator = Profil-Verschleiß-Anzeiger) des <b>ESA+ TECAR</b>{' '}
                                SUPERGRIP PRO{' '}
                                auf gleicher Höhe mit dem verbleibenden Reifenprofil befindet, sollten Sie Ihre Reifen
                                wechseln. Denn dann nimmt die Gefahr von Aquaplaning, Durchdrehen, Rutschen und längerem Bremsweg zu.
                            </p>
                            <p>
                                Durch die „Schneeflocken-Markierung“ im Schulterbereich Ihres <b>TECAR Reifens</b> finden Sie die Position
                                des Winter-TWI im Handumdrehen und bleiben im Winter bei jedem Wetter auf der sicheren Seite.
                            </p>
                            <h2 className="uk-text-bold">Weitere technische Highlights:</h2>
                            <ul className="uk-list uk-list-bullet">
                                <li>Reduziertes Aquaplaning-Risiko durch volumen-optimierte laterale und längslaufende Rillen</li>
                                <li style={{ "marginTop": "1px" }}>
                                    Komfortables Fahren dank speziell geformter Rillen und optimierter Blockstrukturen für geringe
                                    Geräuschentwicklung
                                </li>
                            </ul>
                        </div>

                        <div>
                            <Image src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/aps-landingpage-03-tecar-winterreifen-2023-profil.jpg" alt="Tecar Reifen" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="uk-section uk-section-default uk-section-small" style={{ backgroundColor: '#dd8500' }}>
                <div className="uk-container">
                    <div className="uk-flex-middle uk-grid" data-uk-grid="">
                        <div className="uk-width-1-4@m">
                            <Image
                                src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/TECAR-Reifengarantie.png"
                                alt="Tecar Garantie"
                                className="img_warrenty"
                            />
                        </div>

                        <div className="uk-width-3-4@m">
                            <h2 className="uk-text-bolder">
                                3 JAHRE BESTENS ABGESICHERT: <br />
                                DIE TECAR REIFENGARANTIE.*
                            </h2>
                            <p>
                                Herstellergarantie ist bei TECAR so selbstverständlich, dass wir die handelsüblichen 2 Jahre erweitern.
                                <br />
                                Und zwar auf ganze 3 Jahre.
                                <br />
                                <br />
                                Darüber hinaus bietet Ihnen die erweiterte TECAR REIFENGARANTIE ein zusätzliches Plus: Sie deckt alle durch
                                spitze Gegenstände oder Karambolagen mit Bordsteinkanten verursachte Schäden ab - und greift sogar in Fällen
                                von grobem Vandalismus.
                            </p>
                            <InternalLink className="bgblack uk-width-1-2@m" to="/home/aktionen/tecar-reifen" modifier="secondary">
                                Hier mehr über die Reifengarantie erfahren
                            </InternalLink>
                        
                        </div>
                    </div>
                </div>
            </section>

            
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <div className="uk-child-width-1-2@m uk-grid uk-flex-middle" data-uk-grid="">
                        <div>
                            <h4>TECAR Sommerreifen – direkt in Ihrer Meister-Werkstatt kaufen</h4>
                            <p className="uk-text-bold">
                                Reifen von TECAR bieten Ihnen erstklassige Sicherheit und höchste Qualität zu fairen Preisen – und dies exklusiv aus Ihrem Autohaus. Und für (fast) jedes Auto. Allein die ESA+TECAR SPIRIT PRO Sommerreifen sind in 42 Dimensionen erhältlich. Darüber hinaus bringen sie eine erweiterte Reifengarantie von 3 Jahren mit.
                            </p>
                            
                            <ul className="uk-list uk-list-bullet">
                                <li>Mit integrierter Profiltiefenanzeige (Sommer-TWI) zur schnellen Verschleißerkennung</li>
                                <li style={{ "marginTop": "1px" }}>
                                 Präzises Lenkverhalten auch bei kurvigen Straßen
                                </li>
                                <li style={{ "marginTop": "1px" }}>
                                    Schnelle Wasserverteilung und guter Grip auf nasser Fahrbahn dank breiter, umlaufender Rillen und zahlreichen Lamellen
                                </li>
                                <li style={{ "marginTop": "1px" }}>
                                    Optimale Fahrstabilität durch steife Blockstruktur im Schulterbereich
                                </li>
                            </ul>
                        </div>
                        <div>
                            <Image
                                src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/aps-landingpage-01-tecar-sommerreifen-2023.jpg"
                                alt="Tecar Reifen mit Profilanzeige"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <div className="uk-child-width-1-2@m uk-grid uk-flex-middle" data-uk-grid="">
                        <div>
                            <Image
                                src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/aps-landingpage-02-tecar-sommerreifen-2023.jpg"
                                alt="Tecar guenstige Sommerreifen"
                            />
                        </div>
                        <div>
                            <h4>Der Best-Preis-Performer für jede Straßenlage</h4>
                            <p>
                                Der <b>ESA+TECAR SPIRIT PRO Sommerreifen</b> überzeugt: mit starkem Preis und starker Leistung!
                            </p>
                            <p>
                                Der moderne Hochleistungs-Sommerreifen ist genau richtig <b>für alle, die beim Reifen sparen, 
                                aber in Sachen Sicherheit und Komfort keine Abstriche machen möchten</b>. Denn der <b>ESA+TECAR SPIRIT PRO</b>{' '}   
                                löst den Konflikt zwischen geringem Rollwiderstand und Nässeverhalten optimal. Für besseren Grip auf nasser 
                                Fahrbahn und optimale Sicherheit beim Bremsen – egal ob heißer Autobahn-Asphalt, eine nach sommerlichem Platzregen mit Pfützen übersäte Landstraße oder kurvige Serpentinen.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <div className="uk-child-width-1-2@m uk-grid uk-flex-middle" data-uk-grid="">
                        <div>
                            <h4>Integrierte Profiltiefenanzeige zur schnellen Verschleißerkennung</h4>
                            <p className="uk-text-bold">
                            Reifenfachleute wissen: Das Fahrverhalten bei Sommerreifen reduziert sich überproportional schnell, sobald sich die Restprofiltiefe auf 3 mm oder weniger reduziert.
                            </p>
                            <p>
                                Sobald sich der <b>TWI</b> (Tread Wear Indicator = Profil-Verschleiß-Anzeiger) des <b>ESA+TECAR SPIRIT PRO</b>{' '}
                                auf gleicher Höhe mit dem verbleibenden Reifenprofil befindet, sollten Sie Ihre Reifen wechseln. Denn dann nimmt die Gefahr von Aquaplaning, Durchdrehen, Rutschen und längerem Bremsweg zu.
                            </p>
                            <p>
                                Entdecken Sie selbst, wie der Sommerreifen <b>ESA+TECAR SPIRIT PRO</b> beste Leistung zum besten Preis auf die Straße bringt.
                            </p>
                            <h2 className="uk-text-bold">Weitere technische Highlights:</h2>
                            <ul className="uk-list uk-list-bullet">
                                <li>Optimiertes Profil für bessere Nasshaftung und Bremsleistung</li>
                                <li style={{ "marginTop": "1px" }}>
                                Top Handling dank geschlossener Schulterrillen und stabiler Mittelrillen
                                </li>
                                <li style={{ "marginTop": "1px" }}>
                                Innovative Karkassenkonstruktion für niedrigen Rollwiderstand und gute Hochgeschwindigkeitsstabilität
                                </li>
                            </ul>
                        </div>
                        <div>
                            <Image
                                src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/aps-landingpage-03-tecar-sommerreifen-2023-profil.jpg"
                                alt="Tecar Reifen mit Profilanzeige"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TecarFinder;

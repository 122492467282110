import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

function Textarea({ name, value, label, onChange, onBlur, error, className = 'uk-margin', placeholder }) {
    const innerOnChange = useCallback(({ target: { name, value } }) => onChange({ name, value }), [onChange]);

    return (
        <div className={className}>
            {label && <label className="uk-form-label">{label}</label>}
            <textarea
                rows={5}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={innerOnChange}
                onBlur={onBlur}
                className="uk-textarea uk-form-controls uk-resize-vertical"
            />
            {error && <div className="uk-text-danger">{error}</div>}
        </div>
    );
}

Textarea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    error: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
};

export default Textarea;

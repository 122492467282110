import { shouldFetch } from '../fetchStatus';
import { FETCH_CAR_BRANDS_FAILURE, FETCH_CAR_BRANDS_REQUEST, FETCH_CAR_BRANDS_SUCCESS } from '../actionTypes';
import { apiClient } from '../../helpers/apiHelperV2';
import { carBrandsResponseSelector } from '../selectors/carBrands';

export const fetchCarBrandsIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const carBrandsResponse = carBrandsResponseSelector(state);

        if (shouldFetch(carBrandsResponse.status)) {
            dispatch({
                type: FETCH_CAR_BRANDS_REQUEST,
                payload: {},
            });
            try {
                const res = await apiClient.get('/api/v1/AutoMarken');
                dispatch({
                    type: FETCH_CAR_BRANDS_SUCCESS,
                    payload: { carBrands: res.data.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_CAR_BRANDS_FAILURE,
                    payload: { error },
                });
            }
        }
    };
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { isEmpty, isUndefined } from 'lodash';
import useSWR from 'swr';

import Instruction from './Instruction';
import TireConfiguratorBody from './TireConfiguratorBody';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';
import { fetchGlobalTireOptionsIfNeeded, fetchTireOptionsIfNeeded, updateTiresFilterValues } from '../../../modules/actions/standort/tires';
import {
    globalTireOptionsResponseSelector,
    tireOptionsResponseSelector,
    tiresFilterValuesSelector,
} from '../../../modules/selectors/standort/tires';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { serializeSearchParams } from '../../../helpers/UrlHelper';
import { apiClient } from '../../../helpers/apiHelperV2';
import { useStandortHistory } from '../VHost/StandortHistory';

function TireConfigurator({ options: optionsWithoutAll, season, onSeasonChange, showZipInput, isTecar = false }) {
    const history = useStandortHistory();

    const options = useMemo(() => {
        const { breite, hoehe, durchmesser, geschwindigkeitsindex, tragfaehigkeit } = optionsWithoutAll;

        return {
            breite: ['', ...breite],
            hoehe: ['', ...hoehe],
            durchmesser: ['', ...durchmesser],
            geschwindigkeitsindex: ['', ...geschwindigkeitsindex],
            tragfaehigkeit: ['', ...tragfaehigkeit],
        };
    }, [optionsWithoutAll]);

    const dispatch = useDispatch();
    const standortUrl = useSelector(standortUrlSelector);
    const filterValues = useSelector(tiresFilterValuesSelector);

    const searchQuery = useMemo(() => {
        const { fahrzeug, breite, hoehe, durchmesser, geschwindigkeitsindex, tragfaehigkeit } = filterValues;

        return {
            fahrzeug,
            saison: season,
            breite,
            hoehe,
            durchmesser,
            geschwindigkeitsindex,
            tragfaehigkeit,
            hersteller: isTecar ? 'TECAR' : '',
        };
    }, [filterValues, isTecar, season]);

    const [zipCode, setZipCode] = useState('');

    const { data: newStandortUrlData } = useSWR(
        showZipInput && zipCode.length === 5 ? zipCode : null,
        async zipCode => await apiClient.get('/api/v1/shop-finder', { params: { zipCode } }),
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
            onError: () => {
                window.UIkit.notification('Postleitzahl nicht gefunden.', { status: 'warning', pos: 'top-right' });
            },
        }
    );
    const newStandortUrl = newStandortUrlData?.data?.standortUrl;

    let tireCountKeys;
    if (showZipInput) {
        tireCountKeys = newStandortUrl ? [newStandortUrl, searchQuery] : null;
    } else {
        tireCountKeys = [standortUrl, searchQuery];
    }

    const { data: countData } = useSWR(
        tireCountKeys,
        async (standortUrl, searchQuery) => await apiClient.get(`/api/v1/standorte/${standortUrl}/tires/count`, { params: searchQuery }),
        {
            revalidateOnFocus: false,
            onError: err => {
                window.UIkit.notification(err.message, {
                    status: 'error',
                    pos: 'top-right',
                });
            },
        }
    );
    const tireCount = countData?.data?.count;

    const onSubmit = useCallback(() => {
        history.push({
            newStandortUrl,
            pathname: `/reifen`,
            search: serializeSearchParams(searchQuery, { skipEmptyString: true }),
        });
    }, [history, newStandortUrl, searchQuery]);

    const onFilterValuesChange = useCallback(
        ({ name, value }) => {
            dispatch(updateTiresFilterValues({ standortUrl, filterValuesUpdate: { [name]: value } }));
        },
        [dispatch, standortUrl]
    );

    useEffect(() => {
        const filterValuesUpdate = Object.fromEntries(
            ['breite', 'hoehe', 'durchmesser', 'geschwindigkeitsindex', 'tragfaehigkeit']
                .filter(key => !options[key].includes(filterValues[key]))
                .map(invalidKey => [invalidKey, ''])
        );

        if (!isEmpty(filterValuesUpdate)) {
            dispatch(updateTiresFilterValues({ standortUrl, filterValuesUpdate }));
        }
        // ESLint does not understand property access
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        standortUrl,
        filterValues.breite,
        filterValues.hoehe,
        filterValues.durchmesser,
        filterValues.geschwindigkeitsindex,
        filterValues.tragfaehigkeit,
        options.breite,
        options.hoehe,
        options.durchmesser,
        options.geschwindigkeitsindex,
        options.tragfaehigkeit,
    ]);

    const isButtonEnabled = !!tireCount;

    const submitButtonLabel =
        isUndefined(newStandortUrl) && showZipInput
            ? 'Bitte Postleitzahl eingeben'
            : isUndefined(tireCount)
            ? 'Reifen werden geladen'
            : `${tireCount} Reifen gefunden`;

    return (
        <TireConfiguratorBody
            onSubmit={onSubmit}
            filterValues={filterValues}
            options={options}
            setZipCode={setZipCode}
            isButtonEnabled={isButtonEnabled}
            onFilterValuesChange={onFilterValuesChange}
            season={season}
            onSeasonChange={onSeasonChange}
            submitButtonLabel={submitButtonLabel}
            showZipInput={showZipInput}
        />
    );
}

function LoadAreaTireConfigurator({ tireOptionsResponse, ...props }) {
    return (
        <section className="uk-section uk-section-default uk-section-small">
            <div className="uk-container">
                <div className="uk-child-width-1-2@m uk-grid-collapse uk-grid-match uk-grid" data-uk-grid="">
                    <div>
                        <ReduxSuspense response={tireOptionsResponse}>
                            {options => <TireConfigurator options={options} {...props} />}
                        </ReduxSuspense>
                    </div>
                    <div>
                        <Instruction />
                    </div>
                </div>
            </div>
        </section>
    );
}

const filterByDimensions = false;

const frontload = async ({ fetchTireOptionsIfNeeded, fetchGlobalTireOptionsIfNeeded, season, showZipInput }) => {
    if (showZipInput) {
        await fetchGlobalTireOptionsIfNeeded({ season, filterByDimensions });
    } else {
        await fetchTireOptionsIfNeeded({ season, filterByDimensions });
    }
};

const mapDispatchToProps = { fetchTireOptionsIfNeeded, fetchGlobalTireOptionsIfNeeded };

const mapStateToProps = (state, { showZipInput, season }) => {
    const tireOptionsResponse = showZipInput
        ? globalTireOptionsResponseSelector(state, { season, filterByDimensions })
        : tireOptionsResponseSelector(state, { season, filterByDimensions });

    return {
        tireOptionsResponse,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload, { onUpdate: true })(LoadAreaTireConfigurator));

import React from 'react';
import Input from '../form-elements/Input';
import * as Yup from 'yup';
import { apiClient } from '../../../helpers/apiHelperV2';

const testZipCodeInput = async zipCode => {
    try {
        if (zipCode && zipCode.length === 5) {
            const { data } = await apiClient.post('/api/v1/validation/zipCode', { zipCode });
            return data?.isValid;
        }
        return false;
    } catch (err) {
        return false;
    }
};

function ZipCodeInput({
    label = 'Bitte geben Sie Ihre PLZ ein, damit wir eine Meisterwerkstatt in Ihrer Nähe finden können',
    placeholder = 'Postleitzahl eingeben',
    errors,
    touched,
    values,
    onChange,
    onBlur,
}) {
    return (
        <Input
            label={label}
            name="zipCode"
            value={values.zipCode}
            onChange={onChange}
            onBlur={onBlur}
            error={touched?.zipCode && errors?.zipCode}
            placeholder={placeholder}
            minLength={5}
            maxLength={5}
        />
    );
}

export const ZipCodeInputSchemaFields = {
    zipCode: Yup.string().test({
        name: 'isZipCode',
        exclusive: true,
        message: 'Bitte geben Sie eine gültige deutsche PLZ ein',
        test: testZipCodeInput,
    }),
};

export const ZipCodeInputInitialValues = (prefilledZip = '') => ({
    zipCode: prefilledZip,
});

export default ZipCodeInput;

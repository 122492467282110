import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { InlineSvgImage } from '../Image/Image';
import { tiresFilterValuesSelector } from '../../../modules/selectors/standort/tires';

function Instruction() {
    const refAnimationStart = useRef();
    const refAnimationEnd = useRef();
    const filterValues = useSelector(tiresFilterValuesSelector);
    const { breite, hoehe, durchmesser, geschwindigkeitsindex, tragfaehigkeit } = filterValues;

    useEffect(() => {
        refAnimationEnd.current?.beginElement?.();
        const timeout = setTimeout(() => refAnimationStart.current?.beginElement?.(), 500);
        return () => clearTimeout(timeout);
    }, [breite, hoehe, durchmesser, geschwindigkeitsindex, tragfaehigkeit]);

    const background = 'radial-gradient(circle, rgba(254,254,254,1) 0%, rgba(221,221,221,1) 100%)';
    return (
        <div id="toggleInformation" style={{ background }} className="uk-flex uk-position-relative">
            <div className="uk-grid-collapse uk-grid-match uk-child-width-1-1 uk-grid" data-uk-grid="">
                <div className="uk-position-relative uk-flex uk-flex-bottom">
                    <div className="uk-overlay uk-overlay-primary uk-position-top">
                        <div className="uk-grid uk-grid-small uk-grid-collapse uk-grid-divider" data-uk-grid="">
                            <div className="uk-flex-1 uk-text-center">
                                <div>
                                    B<span className="uk-visible@s">reite</span>
                                </div>
                                <div className="uk-text-small uk-text-light">{breite || '205'}</div>
                            </div>
                            <div className="uk-flex-1 uk-text-center">
                                <div>
                                    H<span className="uk-visible@s">öhe</span>
                                </div>
                                <div className="uk-text-small uk-text-light">{hoehe || '55'}</div>
                            </div>
                            <div className="uk-flex-1 uk-text-center">
                                <div>
                                    Z<span className="uk-visible@s">oll</span>
                                </div>
                                <div className="uk-text-small uk-text-light">{durchmesser || '16'}</div>
                            </div>
                            <div className="uk-flex-1 uk-text-center">
                                <div>
                                    T<span className="uk-visible@s">ragfähigkeit</span>
                                </div>
                                <div className="uk-text-small uk-text-light">{tragfaehigkeit || '75'}</div>
                            </div>
                            <div className="uk-flex-1 uk-text-center">
                                <div>
                                    G<span className="uk-visible@s">eschwindigkeitsindex</span>
                                </div>
                                <div className="uk-text-small uk-text-light">{geschwindigkeitsindex || 'H'}</div>
                            </div>
                        </div>
                    </div>
                    <svg viewBox="0 0 600 320" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <path id="path-0" style={{ stroke: '#000' }} d="M-240,750 A1,1 0 1,1 840,750" stroke="#000" />
                        </defs>
                        <InlineSvgImage
                            width="600"
                            height="320"
                            src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/tecar-reifen-background.webp"
                        />
                        <text style={{ fill: '#fff', fontSize: 50, fontWeight: 700, textAnchor: 'middle' }}>
                            <textPath href="#path-0" startOffset="50%" textAnchor="middle">
                                {`${breite || '*'}/${hoehe || '*'} R${durchmesser || '*'}  ${tragfaehigkeit || '*'} ${
                                    geschwindigkeitsindex || '*'
                                }`}
                                <animate ref={refAnimationStart} attributeName="startOffset" values="0;50%" dur={1} keyTimes="0;1" />
                                <animate ref={refAnimationEnd} attributeName="startOffset" values="50%;100%" dur={1} keyTimes="0;1" />
                            </textPath>
                        </text>
                    </svg>
                </div>
                <div>
                    <div className="uk-padding-small uk-light uk-background-secondary">
                        <h2>Achtung!</h2>
                        <p>
                            Wir empfehlen, die Reifengröße direkt am zu ersetzenden Sommer- oder Winterreifen abzulesen, denn im
                            Fahrzeugschein steht nur EINE von mehreren zugelassenen Reifendimensionen, die nicht zwingend am Fahrzeug
                            montiert sein muss. Die Reifen müssen nicht nur zum Fahrzeug, sondern auch auf die am Fahrzeug verwendete Felge
                            passen.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Instruction;

import React, { useState } from 'react';
import MapSelector from '../Map/MapSelector';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { fetchCarBrandsIfNeeded } from '../../../modules/actions/carBrands';
import { carBrandsSelector } from '../../../modules/selectors/carBrands';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { ContactInquiryCard } from './ServiceStationCards';
import ServiceStationSearchBar from './ServiceStationSearchBar';
import InlineSpinner from '../Spinner/InlineSpinner';
import withStandortRouter from '../VHost/withStandortRouter';
import Button from '../form-elements/Button';
import { standortToMarker } from '../Map/markerHelper';
import { canShowGoogleMapsSelector } from '../../../modules/selectors/oneTrust';
import LocationCardsWrapper from '../LocationLists/LocationCardsWrapper';
import LocationCard from '../LocationLists/LocationCard';
import { InternalLink } from '../form-elements/Link';
import TireModal from '../Anfrage/TireModal';
import { useStandortHistory } from '../VHost/StandortHistory';
import useServiceStations from '../../hooks/useServiceStations';

// "Shopfinder on /de"

function ServiceStationFinder({
    prefilledZip,
    prefilledCarMake,
    autoMarken,
    redirectUrl = '/',
    buttonText = 'Zum Autohaus',
    trailingCards = ({ serviceStationsLength }) => <ContactInquiryCard serviceStationsLength={serviceStationsLength} />,
    // Query params
    tecarHaendler,
    wheels,
    gesellschafter,
    categoryUrl,
    productVariantId,
}) {
    const history = useStandortHistory();
    const canShowGoogleMaps = useSelector(canShowGoogleMapsSelector);

    const [showMobileSearchForm, setShowMobileSearchForm] = useState(true);
    const [showLocationList, setShowLocationList] = useState(true);

    const [zipCode, setZipCode] = useState(prefilledZip);
    const [carMake, setCarMake] = useState(prefilledCarMake);
    const [range, setRange] = useState('50000');

    const { serviceStations, wheelInfoText, isLoading } = useServiceStations(!!zipCode, {
        zipCode,
        carMake: tecarHaendler ? '' : carMake, // todo: why we ignore carMake if selected?
        range,
        categoryUrl: categoryUrl ?? false,
        gesellschafter: gesellschafter ?? false,
        tecarHaendler: tecarHaendler ?? false,
        wheels: wheels ?? false,
        productVariantId,
    });

    const onSubmit = async ({ zipCode, carMake, range }) => {
        setZipCode(zipCode);
        setCarMake(carMake);
        setRange(range);
        setShowMobileSearchForm(false);
    };

    return (
        <>
            <div className="uk-position-relative uk-margin">
                <MapSelector
                    markers={serviceStations?.map(standort =>
                        standortToMarker({
                            standort,
                            onClick: () => {
                                history.push({ pathname: redirectUrl, newStandortUrl: standort.url });
                            },
                        })
                    )}
                />
                {canShowGoogleMaps && (
                    <ServiceStationSearchBar
                        autoMarken={autoMarken}
                        onSubmit={onSubmit}
                        showMobileSearchForm={showMobileSearchForm}
                        setShowMobileSearchForm={setShowMobileSearchForm}
                        prefilledZip={prefilledZip}
                        prefilledCarMake={prefilledCarMake}
                    />
                )}
                {!_.isEmpty(serviceStations) && (
                    <div className="uk-position-bottom-right uk-position-medium">
                        <Button modifier="primary" onClick={() => setShowLocationList(!showLocationList)}>
                            {showLocationList ? 'Adressen ausblenden' : 'Adressen einblenden'}
                        </Button>
                    </div>
                )}
            </div>
            {isLoading && <InlineSpinner />}
            {wheelInfoText && <div className="uk-tile uk-tile-muted uk-tile-small uk-text-center">{wheelInfoText}</div>}
            {showLocationList && serviceStations && (
                <LocationCardsWrapper>
                    {serviceStations.map((location, index) => (
                        <div key={location._id}>
                            <LocationCard
                                location={location}
                                positionNumber={index + 1}
                                footerChildren={
                                    location.hatShop ? (
                                        <InternalLink fullWidth to={redirectUrl} newStandortUrl={location.url} modifier="secondary">
                                            {buttonText}
                                        </InternalLink>
                                    ) : (
                                        // !!!ATTENTION!!!: location could be MARKENSTANDORT / ONBOARDSTANDORT / GESELLSCHAFTER
                                        <TireModal gesellschafter={location}>
                                            <Button fullWidth modifier="secondary" onClick={() => {}}>
                                                Zum Anfrageformular
                                            </Button>
                                        </TireModal>
                                    )
                                }
                            />
                        </div>
                    ))}
                    {_.isFunction(trailingCards) ? trailingCards({ serviceStationsLength: serviceStations.length ?? 0 }) : trailingCards}
                </LocationCardsWrapper>
            )}
        </>
    );
}

const frontload = async props => {
    await props.fetchCarBrandsIfNeeded();
};

const mapStateToProps = state => ({
    autoMarken: carBrandsSelector(state),
    markenStandort: markenStandortSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCarBrandsIfNeeded }, dispatch);

export default withStandortRouter(connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload)(ServiceStationFinder)));

import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import Button from '../form-elements/Button';
import Select from '../form-elements/Select';
import ZipCodeInput, { ZipCodeInputInitialValues, ZipCodeInputSchemaFields } from '../form-fragments/ZipCodeInput';

const ServiceStationSearchBarSchema = Yup.object().shape({
    ...ZipCodeInputSchemaFields,
    carMake: Yup.string(),
    range: Yup.string().required('Pflichtfeld'),
});

function ServiceStationSearchBar({
    autoMarken = [],
    onSubmit,
    showMobileSearchForm,
    setShowMobileSearchForm,
    prefilledZip,
    prefilledCarMake,
}) {
    return (
        <div className="uk-overlay uk-overlay-primary uk-position-top">
            <Formik
                initialValues={{
                    ...ZipCodeInputInitialValues(prefilledZip),
                    carMake: prefilledCarMake || '',
                    range: '50000',
                }}
                validationSchema={ServiceStationSearchBarSchema}
                onSubmit={values => onSubmit(values)}
                render={({ values, handleBlur, setFieldValue, touched, errors }) => (
                    <Form className={classnames('uk-form', { 'uk-visible@m': !showMobileSearchForm })}>
                        <div className="uk-grid-small uk-grid uk-child-width-1-4@m" data-uk-grid="">
                            <div>
                                <ZipCodeInput
                                    values={values}
                                    label="Postleitzahl wählen"
                                    placeholder="z.B. 10117"
                                    onChange={({ name, value }) => setFieldValue(name, value)}
                                    onBlur={handleBlur}
                                />
                                {errors.zipCode && touched.zipCode && <div className="uk-text-danger">{errors.zipCode}</div>}
                            </div>
                            <div>
                                <Select
                                    name="carMake"
                                    value={values.carMake}
                                    label="Automarke wählen"
                                    onChange={({ name, value }) => setFieldValue(name, value)}
                                    onBlur={handleBlur}
                                    error={touched.carMake && errors.carMake}
                                    options={[
                                        { label: 'z.B. BMW', value: '' },
                                        ...autoMarken.map(autoMarke => ({ label: autoMarke.marke, value: autoMarke.marke })),
                                    ]}
                                />
                            </div>
                            <div>
                                <Select
                                    name="range"
                                    value={values.range}
                                    label="Umkreis wählen"
                                    onChange={({ name, value }) => setFieldValue(name, value)}
                                    onBlur={handleBlur}
                                    error={touched.range && errors.range}
                                    options={[
                                        { label: '5 KM', value: '5000' },
                                        { label: '10 KM', value: '10000' },
                                        { label: '20 KM', value: '20000' },
                                        { label: '30 KM', value: '30000' },
                                        { label: '50 KM', value: '50000' },
                                        { label: '100 KM', value: '100000' },
                                    ]}
                                />
                            </div>
                            <div>
                                <div className="uk-margin">
                                    {/* placeholder for empty label */}
                                    <canvas width={1} height={18} />
                                    <Button modifier="secondary" fullWidth type="submit">
                                        Werkstatt finden
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            />
            <div className={classnames(showMobileSearchForm ? 'uk-hidden' : 'uk-hidden@m')}>
                <Button modifier="default" onClick={() => setShowMobileSearchForm(true)} fullWidth>
                    Neue Suche starten
                </Button>
            </div>
        </div>
    );
}

export default ServiceStationSearchBar;

import { createSelector } from 'reselect';
import { standortStateSelector } from '../standort';
import _ from 'lodash';
import { pendingResponse } from '../../fetchStatus';
import { allSeasonOptions, defaultSeason } from '../../../helpers/tiresHelper';
import { categoryBlacklistUrlsSelector } from './markenStandort';
import { serializeSearchParams } from '../../../helpers/UrlHelper';
import { extraParamsSelector } from '../util';

// Seasons
export const validSeasonOptionsSelector = createSelector([categoryBlacklistUrlsSelector], categoryBlacklistUrls =>
    allSeasonOptions.filter(season => !categoryBlacklistUrls.includes(season.value))
);
export const validSeasonsSelector = createSelector([validSeasonOptionsSelector], validSeasonOptions =>
    validSeasonOptions.map(season => season.value)
);
export const validDefaultSeasonSelector = createSelector([validSeasonsSelector], validSeasons => {
    return validSeasons.includes(defaultSeason) ? defaultSeason : _.first(validSeasons);
});

// Extra Params
const seasonSelector = createSelector([extraParamsSelector, validSeasonsSelector], (params, validSeasons) => {
    const { season } = params;
    if (!_.isString(season) || !validSeasons.includes(season)) {
        throw new Error(`Expected valid season, instead got: ${season}`);
    }
    return season;
});
const filterByDimensionsSelector = createSelector([extraParamsSelector], params => {
    const { filterByDimensions } = params;
    if (!_.isBoolean(filterByDimensions)) {
        throw new Error(`Expected valid filterByDimensions, instead got: ${filterByDimensions}`);
    }
    return filterByDimensions;
});
const selectedOptionsSelector = createSelector([extraParamsSelector], params => {
    const { selectedOptions } = params;
    if (!_.isPlainObject(selectedOptions)) {
        throw new Error(`Expected valid selectedOptions, instead got: ${selectedOptions}`);
    }
    return selectedOptions;
});

// Root
const tiresStateSelector = createSelector([standortStateSelector], standortState => standortState.tires);
export const tiresFilterValuesSelector = createSelector([tiresStateSelector], tiresState => tiresState.filterValues);

// TireOptions
const tireOptionsResponseBySearchParamsSelector = createSelector(
    [tiresStateSelector],
    tiresState => tiresState.tireOptionsResponseBySearchParams
);
// Used by tire forms/finder
export const tiresOptionsSearchParamsSelector = createSelector(
    [tiresFilterValuesSelector, seasonSelector, filterByDimensionsSelector],
    ({ fahrzeug, breite, hoehe, durchmesser, geschwindigkeitsindex, tragfaehigkeit, hersteller }, season, filterByDimensions) => {
        return serializeSearchParams(
            {
                saison: season,
                fahrzeug,
                ...(filterByDimensions
                    ? {
                          breite,
                          hoehe,
                          durchmesser,
                      }
                    : {}),
            },
            { skipEmptyString: true }
        );
    }
);
// used by tires/tire list
export const tiresOptionsSearchParamsForSelectedOptionsSelector = createSelector(
    [selectedOptionsSelector, filterByDimensionsSelector],
    ({ saison, fahrzeug, breite, hoehe, durchmesser }, filterByDimensions) => {
        return serializeSearchParams(
            {
                saison,
                fahrzeug,
                ...(filterByDimensions
                    ? {
                          breite,
                          hoehe,
                          durchmesser,
                      }
                    : {}),
            },
            { skipEmptyString: true }
        );
    }
);
export const tireOptionsResponseSelector = createSelector(
    [tireOptionsResponseBySearchParamsSelector, tiresOptionsSearchParamsSelector],
    (tireOptionsResponseBySearchParams, tiresOptionsSearchParams) =>
        tireOptionsResponseBySearchParams?.[tiresOptionsSearchParams] ?? pendingResponse
);
export const tireOptionsForSelectedOptionsResponseSelector = createSelector(
    [tireOptionsResponseBySearchParamsSelector, tiresOptionsSearchParamsForSelectedOptionsSelector],
    (tireOptionsResponseBySearchParams, tiresOptionsSearchParams) =>
        tireOptionsResponseBySearchParams?.[tiresOptionsSearchParams] ?? pendingResponse
);

// Global TireOptions (National TireConfigurator with showZipInput)
const globalTiresStateSelector = state => state.tires;
const globalTireOptionsBySearchParamsSelector = createSelector(
    [globalTiresStateSelector],
    globalTiresState => globalTiresState.globalTireOptionsBySearchParams
);
export const globalTireOptionsResponseSelector = createSelector(
    [globalTireOptionsBySearchParamsSelector, tiresOptionsSearchParamsSelector],
    (globalTireOptionsBySearchParams, tiresOptionsSearchParams) =>
        globalTireOptionsBySearchParams?.[tiresOptionsSearchParams] ?? pendingResponse
);

// Tires
const tiresResponseBySearchParamsSelector = createSelector([tiresStateSelector], tiresState => tiresState.tiresResponseBySearchParams);
export const tiresSearchParamsSelector = createSelector(
    [selectedOptionsSelector],
    ({
        saison,
        fahrzeug,
        breite,
        hoehe,
        durchmesser,
        hersteller,
        geschwindigkeitsindex,
        tragfaehigkeit,
        zustellung,
        preisAb,
        preisBis,
        skip,
        limit,
    }) => {
        return serializeSearchParams(
            {
                saison,
                fahrzeug,
                breite,
                hoehe,
                durchmesser,
                geschwindigkeitsindex,
                tragfaehigkeit,
                hersteller,
                zustellung,
                preisAb,
                preisBis,
                skip,
                limit,
            },
            { skipEmptyString: true }
        );
    }
);
export const tiresResponseSelector = createSelector(
    [tiresResponseBySearchParamsSelector, tiresSearchParamsSelector],
    (tiresResponseBySearchParams, tiresSearchParams) => tiresResponseBySearchParams?.[tiresSearchParams] ?? pendingResponse
);

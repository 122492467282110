import React from 'react';
import Input from '../form-elements/Input';
import Textarea from '../form-elements/Textarea';
import PersonalInformation from '../form-fragments/PersonalInformation';
import Anchor from '../form-elements/Anchor';

function PersonalInformationAccordionItem({ setFieldValue, handleBlur, errors, touched, values }) {
    const onChange = ({ name, value }) => setFieldValue(name, value);

    return (
        <li className="uk-open">
            <Anchor className="uk-accordion-title" style={{ fontSize: 'inherit' }}>
                <div className="uk-flex uk-flex-middle">
                    <span className="uk-badge aps-background-primary-light uk-margin-small-right">1</span>
                    <div className="step-title uk-text-bold uk-text-large">Persönliche Informationen</div>
                </div>
            </Anchor>
            <div className="uk-accordion-content uk-margin-remove">
                <div style={{ marginLeft: 10, paddingLeft: 10, borderLeft: '1px solid grey' }}>
                    <div className="uk-padding-small">
                        <div className="uk-text-bold">Alle mit * gekennzeichneten Felder sind Pflichtfelder</div>
                        <PersonalInformation errors={errors} touched={touched} values={values} onChange={onChange} onBlur={handleBlur} />
                        <Input
                            name="email"
                            value={values.email}
                            type="email"
                            label="E-Mail *"
                            placeholder="E-Mail"
                            onChange={onChange}
                            onBlur={handleBlur}
                            error={touched.email && errors.email ? errors.email : undefined}
                        />
                        <Textarea
                            name="message"
                            value={values.message}
                            label="Anliegen *"
                            placeholder="Beschreiben Sie Ihr Anliegen"
                            onChange={onChange}
                            onBlur={handleBlur}
                            error={touched.message && errors.message ? errors.message : undefined}
                        />
                    </div>
                </div>
            </div>
        </li>
    );
}

export default PersonalInformationAccordionItem;

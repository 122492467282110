import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import Select from '../form-elements/Select';
import Input from '../form-elements/Input';

import { carBrandNamesSelector } from '../../../modules/selectors/carBrands';
import { allSeasonOptions, carTypes } from '../../../helpers/tiresHelper';
import { generateOptionsArray } from '../../../helpers/formHelper';
import { apiClient } from '../../../helpers/apiHelperV2';
import { fetchGlobalTireOptionsIfNeeded, updateTiresFilterValues } from '../../../modules/actions/standort/tires';
import { globalTireOptionsResponseSelector } from '../../../modules/selectors/standort/tires';
import { frontloadConnect } from 'react-frontload';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';
import { standortUrlSelector } from '../../../modules/selectors/url';
import Anchor from '../form-elements/Anchor';

function TireInformation({ setFieldValue, values, tireOptionsResponse }) {
    const [carModels, setCarModels] = useState([]);
    const carBrands = useSelector(carBrandNamesSelector);
    const standortUrl = useSelector(standortUrlSelector);
    const dispatch = useDispatch();

    const getCarModelsForManufacturer = useCallback(({ manufacturer }) => {
        apiClient
            .get('/api/v1/CarModelsForManufacturer', { params: { manufacturer } })
            .then(response => {
                const data = response?.data?.data || [];
                setCarModels(
                    generateOptionsArray({
                        values: data.map(carModel => carModel.Value),
                        additionalOption: { label: 'Alle', value: '' },
                    })
                );
            })
            .catch(err => {
                console.error(err);
                setCarModels([]);
            });
    }, []);

    useEffect(() => {
        for (const value of ['breite', 'hoehe', 'durchmesser', 'geschwindigkeitsindex', 'tragfaehigkeit']) {
            setFieldValue(value, '');
        }
    }, [setFieldValue, values.fahrzeug, values.saison]);

    return (
        <ReduxSuspense response={tireOptionsResponse}>
            {options => (
                <li className="uk-open">
                    <Anchor className="uk-accordion-title" style={{ fontSize: 'inherit' }}>
                        <div className="uk-flex uk-flex-middle">
                            <span className="uk-badge aps-background-primary-light uk-margin-small-right">2</span>
                            <div className="step-title uk-text-bold uk-text-large">Fahrzeug Informationen</div>
                        </div>
                    </Anchor>
                    <div className="uk-accordion-content uk-margin-remove">
                        <div
                            style={{
                                marginLeft: 10,
                                paddingLeft: 10,
                                borderLeft: '1px solid grey',
                            }}
                        >
                            <div className="uk-padding-small">
                                <div className="uk-margin">
                                    <p className="uk-text-bold">Alle mit * gekennzeichneten Felder sind Pflichtfelder</p>
                                </div>
                                <div className="uk-grid-small uk-child-width-expand@s uk-grid" data-uk-grid="">
                                    <div>
                                        <Select
                                            name="fahrzeug"
                                            value={values.fahrzeug}
                                            label="Fahrzeug"
                                            onChange={({ name, value }) => {
                                                setFieldValue(name, value);
                                                dispatch(updateTiresFilterValues({ standortUrl, filterValuesUpdate: { [name]: value } }));
                                            }}
                                            options={carTypes}
                                        />
                                    </div>
                                    <div>
                                        <Select
                                            name="saison"
                                            value={values.saison}
                                            label="Saison"
                                            onChange={({ name, value }) => setFieldValue(name, value)}
                                            options={allSeasonOptions}
                                        />
                                    </div>
                                    <div>
                                        <Select
                                            name="automarke"
                                            value={values.automarke}
                                            label="Automarke"
                                            onChange={({ name, value }) => {
                                                getCarModelsForManufacturer({ manufacturer: value });
                                                return setFieldValue(name, value);
                                            }}
                                            options={generateOptionsArray({
                                                values: carBrands,
                                                additionalOption: { label: 'Alle', value: '' },
                                            })}
                                        />
                                    </div>
                                    <div>
                                        {carModels.length ? (
                                            <Select
                                                name="automodell"
                                                value={values.automodell}
                                                label="Automodell"
                                                onChange={({ name, value }) => setFieldValue(name, value)}
                                                options={carModels}
                                            />
                                        ) : (
                                            <Input
                                                name="automodell"
                                                value={values.automodell}
                                                label="Automodell"
                                                onChange={({ name, value }) => setFieldValue(name, value)}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="uk-margin uk-grid-small uk-child-width-expand@s uk-grid" data-uk-grid="">
                                    <div>
                                        <Select
                                            name="breite"
                                            value={values.breite}
                                            label="Breite"
                                            onChange={({ name, value }) => setFieldValue(name, value)}
                                            options={generateOptionsArray({
                                                values: options.breite,
                                                additionalOption: { label: 'Alle', value: '' },
                                            })}
                                        />
                                    </div>
                                    <div>
                                        <Select
                                            name="hoehe"
                                            value={values.hoehe}
                                            label="Höhe"
                                            onChange={({ name, value }) => setFieldValue(name, value)}
                                            options={generateOptionsArray({
                                                values: options.hoehe,
                                                additionalOption: { label: 'Alle', value: '' },
                                            })}
                                        />
                                    </div>
                                    <div>
                                        <Select
                                            name="durchmesser"
                                            value={values.durchmesser}
                                            label="Zoll"
                                            onChange={({ name, value }) => setFieldValue(name, value)}
                                            options={generateOptionsArray({
                                                values: options.durchmesser,
                                                additionalOption: { label: 'Alle', value: '' },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="uk-margin uk-grid-small uk-child-width-expand@s uk-grid" data-uk-grid="">
                                    <div>
                                        <Select
                                            name="geschwindigkeitsindex"
                                            value={values.geschwindigkeitsindex}
                                            label="Geschwindigkeitsindex"
                                            onChange={({ name, value }) => setFieldValue(name, value)}
                                            options={generateOptionsArray({
                                                values: options.geschwindigkeitsindex,
                                                additionalOption: { label: 'Alle', value: '' },
                                            })}
                                        />
                                    </div>
                                    <div>
                                        <Select
                                            name="tragfaehigkeit"
                                            value={values.tragfaehigkeit}
                                            label="Tragfähigkeit"
                                            onChange={({ name, value }) => setFieldValue(name, value)}
                                            options={generateOptionsArray({
                                                values: options.tragfaehigkeit,
                                                additionalOption: { label: 'Alle', value: '' },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )}
        </ReduxSuspense>
    );
}

const filterByDimensions = false;

const frontload = async ({ fetchGlobalTireOptionsIfNeeded, season }) => {
    await fetchGlobalTireOptionsIfNeeded({ season, filterByDimensions });
};

const mapDispatchToProps = { fetchGlobalTireOptionsIfNeeded };

const mapStateToProps = (state, { values }) => {
    const season = values.saison;

    return {
        season,
        tireOptionsResponse: globalTireOptionsResponseSelector(state, { season, filterByDimensions }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload, { onUpdate: true })(TireInformation));

import * as Yup from 'yup';
import Checkbox from '../form-elements/Checkbox';
import Input from '../form-elements/Input';
import Select from '../form-elements/Select';
import React from 'react';

export function SalutationInformation({ errors, touched, values, onChange, onBlur }) {
    return (
        <>
            <Checkbox name="isCompany" value={values.isCompany} label="Firma" onChange={onChange} onBlur={onBlur} />
            {values.isCompany && (
                <Input
                    name="company"
                    value={values.company}
                    label="Firmenname *"
                    placeholder="Firmenname"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={touched.company && errors.company ? errors.company : undefined}
                />
            )}
            <Select
                name="salutation"
                label="Anrede *"
                value={values.salutation}
                onChange={onChange}
                onBlur={onBlur}
                options={[
                    {
                        label: 'Anrede wählen',
                        value: '',
                    },
                    { label: 'Frau', value: 'Frau' },
                    { label: 'Herr', value: 'Herr' },
                    { label: 'Divers', value: 'Divers' },
                ]}
                error={touched.salutation && errors.salutation ? errors.salutation : undefined}
            />
            <Select
                name="titel"
                value={values.titel}
                label="Titel"
                onChange={onChange}
                onBlur={onBlur}
                options={[
                    {
                        label: 'Titel wählen',
                        value: '',
                    },
                    { value: 'Dr.', label: 'Dr.' },
                    { value: 'Ing.', label: 'Ing.' },
                    { value: 'Prof.', label: 'Prof.' },
                ]}
            />
            <Input
                name="firstName"
                value={values.firstName}
                label="Vorname *"
                placeholder="Vorname"
                onChange={onChange}
                onBlur={onBlur}
                error={touched.firstName && errors.firstName ? errors.firstName : undefined}
            />
            <Input
                name="lastName"
                value={values.lastName}
                label="Nachname *"
                placeholder="Nachname"
                onChange={onChange}
                onBlur={onBlur}
                error={touched.lastName && errors.lastName ? errors.lastName : undefined}
            />
        </>
    );
}

export default SalutationInformation;

export const SalutationInformationSchemaFields = {
    isCompany: Yup.boolean(),
    company: Yup.string().when('isCompany', {
        is: true,
        then: Yup.string().required('Pflichtfeld'),
    }),
    salutation: Yup.string().required('Pflichtfeld'),
    titel: Yup.string(),
    firstName: Yup.string().required('Pflichtfeld'),
    lastName: Yup.string().required('Pflichtfeld'),
};
export const mergeSalutationInformationInitialValues = values => ({
    isCompany: values?.isCompany ?? false,
    company: values?.company ?? '',
    salutation: values?.salutation ?? '',
    titel: values?.titel ?? '',
    firstName: values?.firstName ?? '',
    lastName: values?.lastName ?? '',
});

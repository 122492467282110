import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Image from '../Image/Image';
import PersonalInformationAccordionItem from './PersonalInformationAccordionItem';
import TireInformation from './TireInformation';

import { tiresFilterValuesSelector } from '../../../modules/selectors/standort/tires';
import { userSelector } from '../../../modules/selectors/auth';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { apiClient } from '../../../helpers/apiHelperV2';
import { defaultSeason } from '../../../helpers/tiresHelper';
import Button from '../form-elements/Button';
import { mergePersonalInformationInitialValues, PersonalInformationSchemaFields } from '../form-fragments/PersonalInformation';
import Checkbox from '../form-elements/Checkbox';
import StandortLink from '../VHost/StandortLink';

const TireSchema = Yup.object().shape({
    ...PersonalInformationSchemaFields,
    email: Yup.string().email('Bitte geben Sie eine gültige E-Mail-Adresse ein').required('Pflichtfeld'),
    message: Yup.string().required('Pflichtfeld'),
    fahrzeug: Yup.string(),
    saison: Yup.string(),
    automarke: Yup.string(),
    automodell: Yup.string(),
    breite: Yup.string(),
    hoehe: Yup.string(),
    durchmesser: Yup.string(),
    geschwindigkeitsindex: Yup.string(),
    tragfaehigkeit: Yup.string(),
    privacy: Yup.bool().oneOf([true], 'Pflichtfeld').required('Pflichtfeld'),
});

function TireForm({ closeFormModal, gesellschafter }) {
    const user = useSelector(userSelector);
    const standortUrl = useSelector(standortUrlSelector);
    const tiresFilterValues = useSelector(tiresFilterValuesSelector);

    const initialValues = {
        ...mergePersonalInformationInitialValues(user),
        email: user?.email ?? '',
        message: '',
        fahrzeug: tiresFilterValues.fahrzeug,
        saison: defaultSeason,
        automarke: '',
        automodell: '',
        breite: '',
        hoehe: '',
        durchmesser: '',
        geschwindigkeitsindex: '',
        tragfaehigkeit: '',
        privacy: false,
        bestandsKunde: !!user,
    };

    const handleTireFormSubmit = async (values, actions) => {
        try {
            const { data } = await apiClient.post('/api/v1/inquiry/tire', { ...values, gesellschafter }, { params: { standortUrl } });
            window.UIkit.notification(data.message, {
                status: 'success',
                pos: 'top-right',
            });
            actions.setSubmitting(false);
            closeFormModal();
        } catch (err) {
            window.UIkit.notification(err.message, {
                status: 'error',
                pos: 'top-right',
            });
            actions.setSubmitting(false);
        }
    };
    return (
        <>
            <div className="uk-panel uk-padding-small uk-background-secondary uk-light">
                <div className="uk-flex uk-flex-middle">
                    <Image
                        className="uk-margin-right"
                        resizeWidth={40}
                        width="40"
                        src="https://techno-images.s3.eu-central-1.amazonaws.com/images/HeaderImages/APS_Logo.png"
                        alt=""
                    />
                    <h2 className="uk-margin-remove">Ihre Anfrage</h2>
                    <button
                        className="uk-margin-auto-left uk-icon-button"
                        data-uk-icon="close"
                        onClick={closeFormModal}
                        data-uk-tooltip="Anfrage schließen"
                        aria-label="Anfrage schließen"
                    />
                </div>
            </div>
            <div className="uk-modal-body">
                <Formik
                    initialValues={initialValues}
                    validationSchema={TireSchema}
                    onSubmit={handleTireFormSubmit}
                    render={({ values, errors, touched, setFieldValue, handleBlur }) => (
                        <Form className="uk-form">
                            <ul data-uk-accordion="multiple: true">
                                <PersonalInformationAccordionItem
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                />
                                <TireInformation setFieldValue={setFieldValue} values={values} />
                            </ul>
                            <Checkbox
                                name="privacy"
                                value={values.privacy}
                                label={
                                    <>
                                        Ich habe die <StandortLink to="/datenschutz">Datenschutzhinweise</StandortLink> zur Kenntnis
                                        genommen.
                                    </>
                                }
                                onChange={({ name, value }) => setFieldValue(name, value)}
                                error={touched.privacy && errors.privacy ? errors.privacy : undefined}
                            />
                            <Button type="submit" fullWidth>
                                {!_.isEmpty(errors) ? 'Bitte füllen Sie die mit * markierten Pflichtfelder aus' : 'Anfrage versenden'}
                            </Button>
                        </Form>
                    )}
                />
            </div>
        </>
    );
}

export default TireForm;
